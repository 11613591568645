@charset "utf-8";


/* ––––––––––––––––––––––––––––––––––––––––––––––––––
ファーストビュー
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.wrap_firstview{
	.main_slider{
		position:relative;
	}
	.inner_imgleft{
		position:absolute;
		left:0;
		top:0;
		width:70px;
		z-index:2;
	}
	.inner_imgright{
		position:absolute;
		right:0;
		bottom:0;
		width:70px;
		z-index:2;
	}
	.inner_title{
		padding:0 20px;
	    position: absolute;
	    top:50%;
	    left: 0;
	    right:0;
	    -webkit-transform: translate(-0%, -50%);
	    -ms-transform: translate(-0%, -50%);
	    transform: translate(-0%, -50%);
	    z-index:2;
	    .block_title{
	    	color:white;
	    	font-size:2.2rem;
	    	font-weight:600;
	    	text-align:center;
	    	text-shadow:0px 0px 3px rgba(2, 2, 2, 1);
	    }
	}
	.inner_scroll{
	    margin: 0 auto;
	    position: absolute;
	    right: 0;
	    left: 0;
	    bottom: 20px;
        text-align: center;
        z-index:2;
		.block_img{
			margin-bottom: 10px;
			img{
				height:30px;
				width:7px;
			}
		}
		.block_txt{
			color:white;
			font-size:1.2rem;
		}
	}
	.slider{
		height:100%;
		position: relative;
		width:100%;
		.slide {
			position: absolute;
			top: 0;
			left: 0;
			background-repeat: no-repeat;
			background-position: center center;
			background-size: cover;
			z-index: 1;
			height: 100%;
			width: 100%;
		}
		.slide:not(:first-child) {
			display: none;
		}
	}
	// .bg_img{
	// 	background-position:70% 0;
	// 	background-repeat:no-repeat;
	// 	background-size:cover;
	// 	height:100%;
	// }

}
@media only screen and (min-width: 767px) {
	.wrap_firstview{
		.inner_imgleft{
			width:145px;
		}
		.inner_imgright{
			width:145px;
		}

		.inner_title{
			padding:0 30px;
		    .block_title{
		    	font-size:3.2rem;
		    	text-shadow:0px 0px 10px rgba(2, 2, 2, 1);
		    }
		}

		.inner_scroll{
			.block_img{
				img{
				}
			}
			.block_txt{
			}
		}
		// .bg_img{
		// 	background-position:center top;
		// }

	}
}
/* ––––––––––––––––––––––––––––––––––––––––––––––––––
トップページ共通サイト幅css
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.wrap_section{
	padding: 50px 0;
	a{
		border:none;
	}
	.inner_content{
		padding:0px 30px;
	}
	.com_toptitle{
		color:#0089d1;
		font-size:2.2rem;
		font-weight:600;
		margin-bottom: 20px;
		text-align:center;
		span{
			background:linear-gradient(0deg, transparent 15%, white 15%, #fff000 15%, #fff000 30%, transparent 30%);
			padding: 0 10px;
		}
	}
}
@media only screen and (min-width: 767px) {
	.wrap_section{
		padding: 100px 0;
		.inner_content{
			margin:0 auto;
			max-width:1080px;
			.com_bqtitle{
				span{
					display:inline-block;
					font-size:2.4rem;
				    padding: 0 55px;
				}
			}
		}
		.com_toptitle{
			font-size:2.8rem;
			margin-bottom: 50px;
			span{
				&:after{
				}
			}
		}
	}
}



/* ––––––––––––––––––––––––––––––––––––––––––––––––––
wrap_topbox01
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.wrap_sect01{
	background: white;
	.inner_content{
		.block_box{
			display: flex;
			flex-flow: wrap row;
			justify-content: space-between;
			margin-bottom: 20px;
			.block_col{
				text-align:center;
				width:calc(100% / 2 - 5px);
				&:nth-child(n+3){
					margin-top: 5px;
				}
				&:nth-child(n+7){
					display:none;
				}
				.item_cap{
					font-size:1.2rem;
					margin-top:3px;
				}
			}
		}
		.block_link{
			margin:0 auto;
			text-align:center;
			width:160px;
			a{
				background-color: #004aab;
				color:white;
				display:block;
				font-size:1.5rem;
				height:40px;
				line-height: 40px;
			}
		}
	}
}

@media only screen and (min-width: 767px) {
	.wrap_sect01{
		.inner_content{
			.block_box{
				margin-bottom: 40px;
				.block_col{
					width:calc(100% / 5 - 32px);
					&:nth-child(n+3){
						margin-top: 0px;
					}
					&:nth-child(n+6){
						margin-top:30px;
					}
					&:nth-child(n+7){
						display:block;
					}
					.item_cap{
						font-size:1.4rem;
						margin-top:6px;
					}
				}
			}
			.block_link{
				a{
				}
			}
		}
	}
}

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
wrap_sect02
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.wrap_sect02{
	overflow: hidden;
    padding: 50px 0 0;
    position:relative;
    &::before{
		background:url(../images/bg_fiximg.jpg) no-repeat center center;
		background-size: cover;
	    background-position: left center;
		display: block;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		content: "";
		z-index: -2;
		opacity:0;
		transition: all 0.3s ease;
    }

    &.show_bg{
	    &::before{
	    	opacity:1;
   			transition: all 0.3s ease;
	    }
    }
    .inner_title{
		color:#fff000;
		span{
			background:linear-gradient(0deg, transparent 15%, white 15%, #0089d1 15%, #0089d1 30%, transparent 30%);
			padding: 0 10px;
		}
    }
    .inner_content{
    	.block_content{
    		color:white;
    		padding:40px 30px;
    		text-shadow:1px 1px 10px rgba(0,0,0,1);
    		.item_title{
    			font-size:2.2rem;
    			line-height:1.5;
    			margin-bottom:15px;
    		}
    		.item_txt{
    			font-size:1.5rem;
    		}
    	}
    	&.inner_bg{
    		background-color: rgba(0,137,209,.9);
    		background-image:url(../images/sect02_bg01.png);
    		background-size:cover;
    		background-position:right center;
    		background-repeat:no-repeat;
    		&.inner_bg02{
	    		background-image:url(../images/sect02_bg02.png);
    		}
    		&.inner_bg03{
	    		background-image:url(../images/sect02_bg03.png);
    		}
    	}
    }
}


@media only screen and (min-width: 767px) {
	.wrap_sect02{
	    background-image: url(../images/bg_fiximg.jpg);
	    background-attachment: fixed;
	    background-size: cover;
        background-position: left center;
	    background-repeat:no-repeat;
	    padding: 100px 0 0;
	    &:before{
	    	display:none;
	    }
	    .inner_title{
	    	margin-bottom:70px;
			span{
			}
	    }
	    .inner_content{
    	    max-width: inherit;
	    	.block_content{
    		    max-width: 1080px;
    		    margin:0 auto;
	    		padding:80px 0px;
	    		.item_title{
	    			font-size:2.8rem;
	    			line-height:1.75;
	    			margin-bottom:20px;
	    		}
	    		.item_txt{
	    			font-size:1.8rem;
	    		}
	    	}
	    	&.inner_bg{
	    	    background-size: auto;
	    		.block_content{
		    		padding:80px 210px 80px 0;
	    		}
	    	}
	    	&.inner_no{
	    		.block_content{
		    		padding:80px 0px 80px 210px;
	    		}
	    	}
	    }
	}
}
/* ––––––––––––––––––––––––––––––––––––––––––––––––––
wrap_topbox03
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.wrap_sect03{
	background: white;
	.inner_content{
		.block_box{
			display: flex;
			flex-flow: wrap row;
			justify-content: space-between;
			margin-bottom: 20px;
			.block_col{
				text-align:center;
				width:calc(100% / 2 - 5px);
				&:nth-child(n+3){
					margin-top: 5px;
				}
				&:nth-child(n+5){
					display:none;
				}
				.item_cap{
					font-size:1.2rem;
					margin-top:3px;
				}
			}
		}
		.block_link{
			margin:0 auto;
			text-align:center;
			width:160px;
			a{
				background-color: #004aab;
				color:white;
				display:block;
				font-size:1.5rem;
				height:40px;
				line-height: 40px;
			}
		}
	}
}

@media only screen and (min-width: 767px) {
	.wrap_sect03{
		.inner_content{
			.block_box{
				margin-bottom: 40px;
				.block_col{
					width:calc(100% / 5 - 32px);
					&:nth-child(n+3){
						margin-top: 0px;
					}
					&:nth-child(n+5){
						display:block;
					}
					.item_cap{
						font-size:1.4rem;
						margin-top:6px;
					}
				}
			}
			.block_link{
				a{
				}
			}
		}
	}
}

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
wrap_topbox04
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.wrap_topbox04{

}
@media only screen and (min-width: 767px) {
	.wrap_topbox04{

	}
}


