@charset "UTF-8";
/* ––––––––––––––––––––––––––––––––––––––––––––––––––
ファーストビュー
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.wrap_firstview .main_slider {
  position: relative; }

.wrap_firstview .inner_imgleft {
  position: absolute;
  left: 0;
  top: 0;
  width: 70px;
  z-index: 2; }

.wrap_firstview .inner_imgright {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 70px;
  z-index: 2; }

.wrap_firstview .inner_title {
  padding: 0 20px;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translate(0%, -50%);
  -ms-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
  z-index: 2; }
  .wrap_firstview .inner_title .block_title {
    color: white;
    font-size: 2.2rem;
    font-weight: 600;
    text-align: center;
    text-shadow: 0px 0px 3px #020202; }

.wrap_firstview .inner_scroll {
  margin: 0 auto;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 20px;
  text-align: center;
  z-index: 2; }
  .wrap_firstview .inner_scroll .block_img {
    margin-bottom: 10px; }
    .wrap_firstview .inner_scroll .block_img img {
      height: 30px;
      width: 7px; }
  .wrap_firstview .inner_scroll .block_txt {
    color: white;
    font-size: 1.2rem; }

.wrap_firstview .slider {
  height: 100%;
  position: relative;
  width: 100%; }
  .wrap_firstview .slider .slide {
    position: absolute;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    z-index: 1;
    height: 100%;
    width: 100%; }
  .wrap_firstview .slider .slide:not(:first-child) {
    display: none; }

@media only screen and (min-width: 767px) {
  .wrap_firstview .inner_imgleft {
    width: 145px; }
  .wrap_firstview .inner_imgright {
    width: 145px; }
  .wrap_firstview .inner_title {
    padding: 0 30px; }
    .wrap_firstview .inner_title .block_title {
      font-size: 3.2rem;
      text-shadow: 0px 0px 10px #020202; } }

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
トップページ共通サイト幅css
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.wrap_section {
  padding: 50px 0; }
  .wrap_section a {
    border: none; }
  .wrap_section .inner_content {
    padding: 0px 30px; }
  .wrap_section .com_toptitle {
    color: #0089d1;
    font-size: 2.2rem;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: center; }
    .wrap_section .com_toptitle span {
      background: linear-gradient(0deg, transparent 15%, white 15%, #fff000 15%, #fff000 30%, transparent 30%);
      padding: 0 10px; }

@media only screen and (min-width: 767px) {
  .wrap_section {
    padding: 100px 0; }
    .wrap_section .inner_content {
      margin: 0 auto;
      max-width: 1080px; }
      .wrap_section .inner_content .com_bqtitle span {
        display: inline-block;
        font-size: 2.4rem;
        padding: 0 55px; }
    .wrap_section .com_toptitle {
      font-size: 2.8rem;
      margin-bottom: 50px; } }

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
wrap_topbox01
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.wrap_sect01 {
  background: white; }
  .wrap_sect01 .inner_content .block_box {
    display: flex;
    flex-flow: wrap row;
    justify-content: space-between;
    margin-bottom: 20px; }
    .wrap_sect01 .inner_content .block_box .block_col {
      text-align: center;
      width: calc(100% / 2 - 5px); }
      .wrap_sect01 .inner_content .block_box .block_col:nth-child(n+3) {
        margin-top: 5px; }
      .wrap_sect01 .inner_content .block_box .block_col:nth-child(n+7) {
        display: none; }
      .wrap_sect01 .inner_content .block_box .block_col .item_cap {
        font-size: 1.2rem;
        margin-top: 3px; }
  .wrap_sect01 .inner_content .block_link {
    margin: 0 auto;
    text-align: center;
    width: 160px; }
    .wrap_sect01 .inner_content .block_link a {
      background-color: #004aab;
      color: white;
      display: block;
      font-size: 1.5rem;
      height: 40px;
      line-height: 40px; }

@media only screen and (min-width: 767px) {
  .wrap_sect01 .inner_content .block_box {
    margin-bottom: 40px; }
    .wrap_sect01 .inner_content .block_box .block_col {
      width: calc(100% / 5 - 32px); }
      .wrap_sect01 .inner_content .block_box .block_col:nth-child(n+3) {
        margin-top: 0px; }
      .wrap_sect01 .inner_content .block_box .block_col:nth-child(n+6) {
        margin-top: 30px; }
      .wrap_sect01 .inner_content .block_box .block_col:nth-child(n+7) {
        display: block; }
      .wrap_sect01 .inner_content .block_box .block_col .item_cap {
        font-size: 1.4rem;
        margin-top: 6px; } }

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
wrap_sect02
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.wrap_sect02 {
  overflow: hidden;
  padding: 50px 0 0;
  position: relative; }
  .wrap_sect02::before {
    background: url(../images/bg_fiximg.jpg) no-repeat center center;
    background-size: cover;
    background-position: left center;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    content: "";
    z-index: -2;
    opacity: 0;
    transition: all 0.3s ease; }
  .wrap_sect02.show_bg::before {
    opacity: 1;
    transition: all 0.3s ease; }
  .wrap_sect02 .inner_title {
    color: #fff000; }
    .wrap_sect02 .inner_title span {
      background: linear-gradient(0deg, transparent 15%, white 15%, #0089d1 15%, #0089d1 30%, transparent 30%);
      padding: 0 10px; }
  .wrap_sect02 .inner_content .block_content {
    color: white;
    padding: 40px 30px;
    text-shadow: 1px 1px 10px black; }
    .wrap_sect02 .inner_content .block_content .item_title {
      font-size: 2.2rem;
      line-height: 1.5;
      margin-bottom: 15px; }
    .wrap_sect02 .inner_content .block_content .item_txt {
      font-size: 1.5rem; }
  .wrap_sect02 .inner_content.inner_bg {
    background-color: rgba(0, 137, 209, 0.9);
    background-image: url(../images/sect02_bg01.png);
    background-size: cover;
    background-position: right center;
    background-repeat: no-repeat; }
    .wrap_sect02 .inner_content.inner_bg.inner_bg02 {
      background-image: url(../images/sect02_bg02.png); }
    .wrap_sect02 .inner_content.inner_bg.inner_bg03 {
      background-image: url(../images/sect02_bg03.png); }

@media only screen and (min-width: 767px) {
  .wrap_sect02 {
    background-image: url(../images/bg_fiximg.jpg);
    background-attachment: fixed;
    background-size: cover;
    background-position: left center;
    background-repeat: no-repeat;
    padding: 100px 0 0; }
    .wrap_sect02:before {
      display: none; }
    .wrap_sect02 .inner_title {
      margin-bottom: 70px; }
    .wrap_sect02 .inner_content {
      max-width: inherit; }
      .wrap_sect02 .inner_content .block_content {
        max-width: 1080px;
        margin: 0 auto;
        padding: 80px 0px; }
        .wrap_sect02 .inner_content .block_content .item_title {
          font-size: 2.8rem;
          line-height: 1.75;
          margin-bottom: 20px; }
        .wrap_sect02 .inner_content .block_content .item_txt {
          font-size: 1.8rem; }
      .wrap_sect02 .inner_content.inner_bg {
        background-size: auto; }
        .wrap_sect02 .inner_content.inner_bg .block_content {
          padding: 80px 210px 80px 0; }
      .wrap_sect02 .inner_content.inner_no .block_content {
        padding: 80px 0px 80px 210px; } }

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
wrap_topbox03
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.wrap_sect03 {
  background: white; }
  .wrap_sect03 .inner_content .block_box {
    display: flex;
    flex-flow: wrap row;
    justify-content: space-between;
    margin-bottom: 20px; }
    .wrap_sect03 .inner_content .block_box .block_col {
      text-align: center;
      width: calc(100% / 2 - 5px); }
      .wrap_sect03 .inner_content .block_box .block_col:nth-child(n+3) {
        margin-top: 5px; }
      .wrap_sect03 .inner_content .block_box .block_col:nth-child(n+5) {
        display: none; }
      .wrap_sect03 .inner_content .block_box .block_col .item_cap {
        font-size: 1.2rem;
        margin-top: 3px; }
  .wrap_sect03 .inner_content .block_link {
    margin: 0 auto;
    text-align: center;
    width: 160px; }
    .wrap_sect03 .inner_content .block_link a {
      background-color: #004aab;
      color: white;
      display: block;
      font-size: 1.5rem;
      height: 40px;
      line-height: 40px; }

@media only screen and (min-width: 767px) {
  .wrap_sect03 .inner_content .block_box {
    margin-bottom: 40px; }
    .wrap_sect03 .inner_content .block_box .block_col {
      width: calc(100% / 5 - 32px); }
      .wrap_sect03 .inner_content .block_box .block_col:nth-child(n+3) {
        margin-top: 0px; }
      .wrap_sect03 .inner_content .block_box .block_col:nth-child(n+5) {
        display: block; }
      .wrap_sect03 .inner_content .block_box .block_col .item_cap {
        font-size: 1.4rem;
        margin-top: 6px; } }

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
wrap_topbox04
–––––––––––––––––––––––––––––––––––––––––––––––––– */
